import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { withReduxStateSync } from "redux-state-sync";

export const filterSlice = createSlice({
  name: "productFilter",
  initialState: { filter: [] },
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
});

export const storeDelNetworks = createSlice({
  name: "storeDelNetworks",
  initialState: { delNet: [] },
  reducers: {
    setDelNet: (state, action) => {
      state.delNet = action.payload;
    },
  },
});

export const { setFilter } = filterSlice.actions;
export const { setDelNet } = storeDelNetworks.actions;

const storeDelHostNet = combineReducers({
  storeDelHost: filterSlice.reducer,
  storeDelNet: storeDelNetworks.reducer,
});

export default withReduxStateSync(storeDelHostNet);
