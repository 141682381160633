import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";
import { DelayedRender } from "views/Utils/delayComponent";
import * as serviceWorker from "./serviceWorker";
import { CircularProgress } from "@material-ui/core";
import store from "REDUX/store";
import { Provider } from "react-redux";

const App = React.lazy(() => import("./App"));
ReactDOM.render(
  <Suspense
    fallback={
      <DelayedRender delay={500}>
        <div style={{ margin: "auto" }}>
          <CircularProgress />
        </div>
      </DelayedRender>
    }
  >
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </Suspense>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
