import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { executeAPICall } from "../executeAPICall";

import config from "config";
import {
  getOrganizationId,
  getOrganizationSwithId,
  getToken,
} from "utils/session_storage";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const getInstructionList = createAsyncThunk(
  "GET/INSTRUCTION",
  async () => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/instruction/get/${getOrganizationSwithId()}`,
      baseURL: config.BASE_URL,
    });
  }
);

const getInstructionSlice = createSlice({
  name: "getinstruction",
  initialState,
  reducers: {
    resetInstructionList: (state) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInstructionList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getInstructionList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(getInstructionList.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    });
  },
});

// export const addinstruction = createAsyncThunk("ADD/instruction", async (data) => {
//   return executeAPICall({
//     method: "POST",
//     url: `instruction/Addinstruction`,
//     baseURL: localBaseURL,
//     reqData: data,
//   });
// });

// const addinstructionSlice = createSlice({
//   name: "addinstruction",
//   initialState,
//   reducers: {
//     resetAdd: (state, action) => {
//       state.isLoading = false;
//       state.data = {};
//       state.error = {};
//     },
//   },
//   extraReducers: (builder) => {
//     builder.addCase(addinstruction.pending, (state) => {
//       state.isLoading = true;
//     });
//     builder.addCase(addinstruction.fulfilled, (state, action) => {
//       state.isLoading = false;
//       // state.data = action.payload;
//       state.data = { message: "Added successfully" };
//       state.error = {};
//     });
//     builder.addCase(addinstruction.rejected, (state, action) => {
//       state.isLoading = false;
//       state.data = {};
//       state.error = action.payload;
//     });
//   },
// });

export const { resetinstructionList } = getInstructionSlice.actions;
//   export const { resetAdd } = addinstructionSlice.actions;

const instruction = combineReducers({
  instructionList: getInstructionSlice.reducer,
  // addinstruction: addinstructionSlice.reducer,
});

export default instruction;
