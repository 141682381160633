import axios from "axios";
import { isNil, isEmpty, toLower } from "lodash-es";
import { getToken } from "utils/session_storage";

const axiosInstance = axios.create();

export function executeAPICall({ method, url, reqData, baseURL, reqHeaders }) {
  // NOTE: if you need to set a different API BASE URL, then you would need to explicitly pass the attribute 'baseURL' while making the request
  // TO DO base URL
  const apiBaseURL = (!isNil(baseURL) || !isEmpty(baseURL)) && baseURL;
  return new Promise((resolve, reject) => {
    let requestConfig = {
      method,
      url: `${apiBaseURL}${url}`,
      headers: {
        // "Cache-Control": "no-cache, no-store, must-revalidate",
        // Accept: "application/json",
        // "Content-Type": "application/json",
        // Pragma: "no-cache",
        Authorization: getToken(),
        "content-type": "application/json",
      },
    };
    // WHEN THE REQUEST METHOD IS 'get' THEN PASS THE REQUEST DATA IN THE REQUEST URL AS QUERY STRING PARAMETERS
    if (toLower(method) === "get" && !isEmpty(reqData)) {
      requestConfig = {
        ...requestConfig,
        params: reqData,
      };
    } else if (!isEmpty(reqData)) {
      // FOR ANY REQUEST METHOD OTHER THAN 'get' PASS THE REQUEST DATA IN THE REQUEST BODY
      requestConfig = {
        ...requestConfig,
        data: reqData,
      };
    }
    if (reqHeaders && reqData) {
      requestConfig = {
        ...requestConfig,
        data: reqData,
        headers: reqHeaders,
      };
    }
    axiosInstance(requestConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        let errorToReturn = error;
        console.log("apiCall error");
        console.log("apiCall errorResponse");
        if (error.response) {
          let errorData = error.response.data;
          if (error.response.status === 404) {
            errorData = { reasonPhrase: "API endpoint is not found" };
          } else if (error.message) {
            errorData = { reasonPhrase: error.message };
          }
          errorToReturn = errorData;
        }
        reject(errorToReturn);
      });
  });
}
