import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { executeAPICall } from "../executeAPICall";

import config from "config";
import {
  getOrganizationId,
  getOrganizationSwithId,
  getToken,
} from "utils/session_storage";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const getNetworkDataList = createAsyncThunk(
  "GET/GET-NETWORK-LIST",
  async () => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/network/${getOrganizationSwithId()}`,
      baseURL: config.BASE_URL,
    });
  }
);

const getNetworkDataSlice = createSlice({
  name: "getScanBarData",
  initialState,
  reducers: {
    resetGetNetworkDataList: (state) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNetworkDataList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNetworkDataList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(getNetworkDataList.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    });
  },
});

export const { resetGetNetworkDataList } = getNetworkDataSlice.actions;

const networkAPI = combineReducers({
  getNetworkDataList: getNetworkDataSlice.reducer,
});

export default networkAPI;
