import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { executeAPICall } from "../executeAPICall";

import config from "config";
import {
  getOrganizationId,
  getOrganizationSwithId,
  getToken,
} from "utils/session_storage";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const getOrganizationsDataList = createAsyncThunk(
  "GET/GET-ORGANIZATIONS",
  async () => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/api/org`,
      baseURL: config.BASE_URL,
    });
  }
);

const getOrganizationsDataSlice = createSlice({
  name: "getOrganizationsData",
  initialState,
  reducers: {
    resetGetOrganizationsDataList: (state) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrganizationsDataList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrganizationsDataList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(getOrganizationsDataList.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    });
  },
});

export const {
  resetgetOrganizationsDataList,
} = getOrganizationsDataSlice.actions;

const organizations = combineReducers({
  getOrganizationsDataList: getOrganizationsDataSlice.reducer,
});

export default organizations;
