import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { adminUserId, getToken, userId } from "utils/session_storage";
import { executeAPICall } from "../executeAPICall";
import config from "config";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const getUserProfileAPI = createAsyncThunk(
  "GET/USER/PROFILE",
  async () => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/api/get_user/${adminUserId() ? adminUserId() : userId()}`,
      baseURL: config.BASE_URL,
    });
  }
);

const getUserProfileSlice = createSlice({
  name: "getUsrProfile",
  initialState,
  reducers: {
    resetGetUserProfleURL: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfileAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserProfileAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      // state.data = { message: "Agent created successfully" };
      state.error = {};
    });
    builder.addCase(getUserProfileAPI.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload && action.payload;
      // state.error = { message: "Agent creation is not successful" };
    });
  },
});

export const updateProfileUser = createAsyncThunk(
  "UPDATE/PROFILE",
  async (data) => {
    return executeAPICall({
      method: "PUT",
      url: `/scanner/api/update_user_contact_info`,
      baseURL: config.BASE_URL,
      reqData: data,
    });
  }
);

const updateProfileUserSlice = createSlice({
  name: "updateUser",
  initialState,
  reducers: {
    resetUpdateProfile: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateProfileUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateProfileUser.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.data = action.payload;
      state.data = { message: "Network deleted successfully" };
      state.error = {};
    });
    builder.addCase(updateProfileUser.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      // state.error = action.payload;
      state.error = { message: "Network is not deleted" };
    });
  },
});

export const { resetGetUserProfleURL } = getUserProfileSlice.actions;
export const { resetUpdateProfile } = updateProfileUserSlice.actions;

const profilePage = combineReducers({
  getUsrProfiles: getUserProfileSlice.reducer,
  updateUsers: updateProfileUserSlice.reducer,
});

export default profilePage;
