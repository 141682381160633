import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { withReduxStateSync } from "redux-state-sync";
import { executeAPICall } from "../executeAPICall";
import config from "config";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const deleteNetwork = createAsyncThunk("DELTE/NETOWRK", async (data) => {
  return executeAPICall({
    method: "POST",
    url: `/scanner/network/delete_networks`,
    baseURL: config.BASE_URL,
    // url: `posts`,
    // baseURL: `https://jsonplaceholder.typicode.com/`,
    reqData: data,
  });
});

const deleteNetworkSlice = createSlice({
  name: "deleteNetwork",
  initialState,
  reducers: {
    resetDeleteNetwork: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteNetwork.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteNetwork.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.data = action.payload;
      state.data = { message: "Network deleted successfully" };
      state.error = {};
    });
    builder.addCase(deleteNetwork.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      // state.error = action.payload;
      state.error = { message: "Network is not deleted" };
    });
  },
});

export const deleteHosts = createAsyncThunk("DELTE/HOSTS", async (data) => {
  return executeAPICall({
    method: "POST",
    url: `/scanner/network/host/delete_hosts`,
    baseURL: config.BASE_URL,
    // url: `posts`,
    // baseURL: `https://jsonplaceholder.typicode.com/`,
    reqData: data,
  });
});

const deleteHostsSlice = createSlice({
  name: "deleteHost",
  initialState,
  reducers: {
    resetDeleteHosts: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteHosts.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteHosts.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.data = action.payload;
      state.data = { message: "Host deleted successfully" };
      state.error = {};
    });
    builder.addCase(deleteHosts.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      // state.error = action.payload;
      state.error = { message: "Host is not deleted" };
    });
  },
});

export const { resetDeleteNetwork } = deleteNetworkSlice.actions;
export const { resetDeleteHosts } = deleteHostsSlice.actions;

const deleteNetworkHost = combineReducers({
  deleteNetworks: deleteNetworkSlice.reducer,
  deleteHost: deleteHostsSlice.reducer,
});

export default withReduxStateSync(deleteNetworkHost);
