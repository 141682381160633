// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem("user");
  if (userStr) {
    return JSON.parse(userStr);
  }
  return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem("authorization") || null;
};

// return the org_id from the session storage
export const getOrganizationId = () => {
  return sessionStorage.getItem("org_id") || null;
};

export const getOrganizationSwithId = () => {
  return sessionStorage.getItem("switchOrg") || null;
};

// return the org_name from the session storage
export const getOrganizationName = () => {
  return sessionStorage.getItem("org_name") || null;
};

export const getadminOrganizationId = () => {
  return sessionStorage.getItem("orgid") || null;
};

// return the getUserRole from the session storage
export const getAdminRole = () => {
  return sessionStorage.getItem("rolesd") || null;
};

export const getUserRole = () => {
  return sessionStorage.getItem("role") || null;
};

export const userId = () => {
  return sessionStorage.getItem("id") || null;
};

export const adminUserId = () => {
  return sessionStorage.getItem("userid") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  sessionStorage.setItem("user", JSON.stringify(user));
};

// set the key and value into the session storage
export const set = (key, value) => {
  sessionStorage.setItem(key, value);
};

// remove the key and value into the session storage
export const remove = (key) => {
  sessionStorage.removeItem(key);
};
// get the key and value from the session storage
export const get = (key) => {
  return sessionStorage.getItem(key);
};
