import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { executeAPICall } from "../executeAPICall";

import config from "config";
import {
  getOrganizationId,
  getOrganizationSwithId,
  getToken,
} from "utils/session_storage";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const gethostListAPI = createAsyncThunk(
  "GET/GET-SCAN-HOST-LIST",
  async () => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/network/host/org/${getOrganizationSwithId()}`,
      baseURL: config.BASE_URL,
    });
  }
);

const gethostListDataSlice = createSlice({
  name: "getHostData",
  initialState,
  reducers: {
    resetGethostListAPI: (state) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(gethostListAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(gethostListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(gethostListAPI.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    });
  },
});

export const { resetGethostListAPI } = gethostListDataSlice.actions;

const hostListGetData = combineReducers({
  gethostList: gethostListDataSlice.reducer,
});

export default hostListGetData;
