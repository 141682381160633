import React from "react";

const useDelayedRender = (delay) => {
  const [delayed, setDelayed] = React.useState(true);
  React.useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), delay);
    return () => clearTimeout(timeout);
  }, []);
  return (fn) => !delayed && fn();
};

export const DelayedRender = ({ delay, children }) =>
  useDelayedRender(delay)(() => children);
