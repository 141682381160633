import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { executeAPICall } from "../executeAPICall";

import config from "config";
import {
  getOrganizationId,
  getOrganizationSwithId,
  getToken,
} from "utils/session_storage";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const getScanDashboardCountList = createAsyncThunk(
  "GET/GET-SCAN-DASHBOARD-COUNT",
  async () => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/dashboard/count/${getOrganizationSwithId()}`,
      baseURL: config.BASE_URL,
    });
  }
);

const getScanDashboardCountSlice = createSlice({
  name: "getScanDashboardCount",
  initialState,
  reducers: {
    resetgetScanDashboardCountList: (state) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getScanDashboardCountList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getScanDashboardCountList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(getScanDashboardCountList.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    });
  },
});

export const getScanBarDataList = createAsyncThunk(
  "GET/GET-SCAN-BARCHART",
  async () => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/dashboard/barscaninst/${getOrganizationSwithId()}`,
      baseURL: config.BASE_URL,
    });
  }
);

const getScanBarDataSlice = createSlice({
  name: "getScanBarData",
  initialState,
  reducers: {
    resetgetScanBarDataList: (state) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getScanBarDataList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getScanBarDataList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(getScanBarDataList.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    });
  },
});

export const getScanPieDataList = createAsyncThunk(
  "GET/GET-SCAN-PIECHART",
  async () => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/dashboard/piescaninst/${getOrganizationSwithId()}`,
      baseURL: config.BASE_URL,
    });
  }
);

const getScanPieDataSlice = createSlice({
  name: "getScanBarData",
  initialState,
  reducers: {
    resetgetScanPieDataList: (state) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getScanPieDataList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getScanPieDataList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(getScanPieDataList.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    });
  },
});

export const {
  resetgetScanDashboardCountList,
} = getScanDashboardCountSlice.actions;
export const { resetgetScanBarDataList } = getScanBarDataSlice.actions;
export const { resetgetScanPieDataList } = getScanPieDataSlice.actions;

const homepage = combineReducers({
  getScanDashboardCountList: getScanDashboardCountSlice.reducer,
  getScanBarDataList: getScanBarDataSlice.reducer,
  getScanPieDataList: getScanPieDataSlice.reducer,
});

export default homepage;
