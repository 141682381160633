import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const redirectUrlAPI = createAsyncThunk(
  "GENERATE/TOKEN",
  async (data, { rejectWithValue }) => {
    const base_url = "https://agent.netpropriate.net/createtoken";
    try {
      const result = await axios.post(base_url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (result?.data?.redirectlink.includes("http")) {
        window.open(result.data.redirectlink);
      }
      return result.data;
    } catch (error) {
      return rejectWithValue({ message: error.response.statusText });
    }
  }
);

const redirectURLSlice = createSlice({
  name: "redirectURLData",
  initialState,
  reducers: {
    resetRedirectURL: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(redirectUrlAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(redirectUrlAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      // state.data = { message: "Agent created successfully" };
      state.error = {};
    });
    builder.addCase(redirectUrlAPI.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload && action.payload;
      // state.error = { message: "Agent creation is not successful" };
    });
  },
});

export const agentCraeteAPI = createAsyncThunk(
  "CREATE/AGENT",
  async (data, { rejectWithValue }) => {
    const base_url = "https://agent.netpropriate.net/createagent";
    try {
      const result = await axios.post(base_url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (result?.data?.agent_download_link.includes("http")) {
        window.location.href = result.data.agent_download_link;
      }
      return result.data;
    } catch (error) {
      return rejectWithValue({ message: error.response.statusText });
    }
  }
);

const agentCraeteSlice = createSlice({
  name: "generateAgent",
  initialState,
  reducers: {
    resetAgentCreate: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(agentCraeteAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(agentCraeteAPI.fulfilled, (state, action) => {
      console.log("DATAA ===", action.payload);
      state.isLoading = false;
      state.data = action.payload;
      // state.data = { message: "Agent created successfully" };
      state.error = {};
    });
    builder.addCase(agentCraeteAPI.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload && action.payload;
      // state.error = { message: "Agent creation is not successful" };
    });
  },
});

export const { resetRedirectURL } = redirectURLSlice.actions;
export const { resetAgentCreate } = agentCraeteSlice.actions;

const generateAgent = combineReducers({
  postRedirectURL: redirectURLSlice.reducer,
  postGenerateAgent: agentCraeteSlice.reducer,
});

export default generateAgent;
